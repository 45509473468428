const list = [{
    value: 0,
    name: '全部文件'
}, {
    value: 1,
    name: '待我签'
}, {
    value: 2,
    name: '待他人签'
}, {
    value: 3,
    name: '草稿箱'
}, {
    value: 4,
    name: '已完成'
}, {
    value: 5,
    name: '已失效'
}, {
    value: 6,
    name: '已撤销'
}, {
    value: 7,
    name: '已拒签'
}, {
    value: 8,
    name: '即将截止签署'
}];

// 合同对我的状态；1 : 待我签 , 2: 待他人签 ， 3：草稿 ，4： 已完成 ，5：已失效 , 6 : 已撤销 , 7：已拒签
const fileStatusMyObj = {
    0: '其他',
    1: '待我签',
    2: '待他人签',
    3: '草稿',
    4: '已完成',
    5: '已失效',
    6: '已撤销',
    7: '已拒签'
}
// 合同详情里面，表格，签署人的状态,签署状态； 0 ：待签署 ，1 ：签署 ，-1 ：拒绝
const myFileObj = {
    '0': '未签署',
    '1': '已签署',
    '-1': '拒绝'
}
const fileTypeList = [ 'doc','docx','pdf' , 'xls' , 'xlsx']
export { list, fileStatusMyObj, myFileObj ,fileTypeList};
