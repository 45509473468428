import axios from "axios";
//import { message } from 'antd';
import Cookies from "js-cookie";
import config from "./config";

class HttpRequest {
  constructor() {
    this.baseUrl = config.baseUrl;
  }

  getInsideConfig() {
    // 配置公共headers
    const config = {
      baseURL: this.baseUrl,
      headers: {
        // 设置头部信息
      }
    };
    return config;
  }
  interceptors(instance, url) {
    // 请求拦截
    instance.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // 响应拦截
    instance.interceptors.response.use(
      (res) => {
        const { data, headers } = res;
        //判断是否为二进制文件流，用于下载，headers中包含文件名信息
        if (data.type === "application/octet-stream") {
          return { data, headers };
        }
        return data;
      },
      (error) => {
        let errorInfo = error.response;
        if (!errorInfo) {
          const {
            request: { statusText, status },
            config
          } = JSON.parse(JSON.stringify(error));
          errorInfo = {
            statusText,
            status,
            request: { responseURL: config.url }
          };
        }
        return Promise.reject(error);
      }
    );
  }
  request(options = {}) {
    const instance = axios.create();
    options = Object.assign(this.getInsideConfig(), options);
    this.interceptors(instance, options.url);
    return instance(options)
      .then((res) => {
        return new Promise((resolve, reject) => {
          // if (res.statusCode !== "200") {
          //   let err = new Error(res.message);
          //   err.res = res;
          //   // 若返回的状态 err_no ！== 0 ，提示错误信息

          //   reject(res);
          // }
          // 当前用户未登录！！请先登录！
          if (res.statusCode === "401") {
            //message.error("登录状态过期，请重新登录！");
            setTimeout(() => {
              Cookies.remove("loginstatus");
              Cookies.remove("thirdtype");
              let pathname = window.location.pathname;
              if (
                pathname !== "/" &&
                pathname !== "/login" &&
                pathname !== "/register" &&
                pathname !== "/password"
              ) {
                window.location.href = "/login";
              }
            }, 500);
          }
          resolve(res);
        });
      })
      .catch((error) => {
        throw error;
      });
  }
  get(url, params = {}, options = {}) {
    return this.request({
      method: "get",
      url,
      params,
      ...options
    });
  }
  post(url, data, options = {}) {
    return this.request({
      method: "post",
      url,
      data,
      ...options
    });
  }
}

export default new HttpRequest();

