import React from "react";
import { Route } from "react-router-dom";

export default function URoute({ title, ...rest }) {
  const docTitle = document.title;
  if (title && docTitle !== title) {
    document.title = "数是万物-" + title;
  }
  window.scrollTo(0, 0);
  return <Route {...rest} />;
}
