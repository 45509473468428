import { PHONE, EMAIL, IDCARD } from "./pattern";
import loading from "@images/icon_loading.gif";
import { message } from "antd";
import { fileTypeList } from "./data";
/**
 * 存放全局公用方法
 */
let _this;
let loadingDom = null;
let rootDom = document.getElementById("root");

class utils {
  // 获取url参数
  querySearch(param) {
    var pattern = /([^?&=]+)=([^&#]*)/g;
    var dict = {};
    var search = null;
    if (typeof param === "object" && param instanceof Location) {
      search = param.search;
    } else if (typeof param === "string") {
      search = param;
    } else {
      throw new Error("参数类型非法！请传入window.loaction对象或者url字符串。");
    }
    search.replace(pattern, function (rs, $1, $2) {
      var key = decodeURIComponent($1);
      var value = decodeURIComponent($2);
      dict[key] = value;
      return rs;
    });
    return dict;
  }

  // 接口频繁调用
  debounce(func, wait = 500) {
    let timeout; // 定时器变量
    return function (event) {
      clearTimeout(timeout); // 每次触发时先清除上一次的定时器,然后重新计时
      event.persist && event.persist(); //保留对事件的引用
      //const event = e && {...e}   //深拷贝事件对象
      timeout = setTimeout(() => {
        func(event);
      }, wait); // 指定 xx ms 后触发真正想进行的操作 handler
    };
  }
  // 手机号、身份证、邮箱加密显示
  encryptionShow(value) {
    if (PHONE["zh-CN"].test(value)) {
      let end = value.length;
      return `${value.slice(0, 3)}****${value.slice(end - 4, end)}`;
    }
    if (EMAIL.test(value)) {
      return `${value.slice(0, 3)}****@${value.split("@")[1]}`;
    }
    if (IDCARD.test(value)) {
      value = value + "";
      let end = value.length;
      return `${value.slice(0, 4)}****${value.slice(end - 4, end)}`;
    }
    return value;
  }
  // 下载图片
  downImg(imgPath, name) {
    const image = new Image();
    // 解决跨域 Canvas 污染问题
    image.setAttribute("crossOrigin", "anonymous");
    image.src = imgPath;
    image.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      const context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, image.width, image.height);
      const url = canvas.toDataURL("image/png"); // 得到图片的base64编码数据
      const a = document.createElement("a"); // 生成一个a元素
      const event = new MouseEvent("click"); // 创建一个单击事件
      a.download = name; // 设置图片名称
      a.href = url; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    };
  }
  downloadSign(id) {
    window.open(`/web/fileList/downloadSign?signId=${id}`, "_blank");
  }
  // 两个元素是否重叠展示
  isCrash(obj1, obj2) {
    var boolCrash = true; //假设true为碰撞
    var left1 = _this.getElementToPageLeft(obj1);
    var right1 = left1 + obj1.offsetWidth;
    var top1 = _this.getElementToPageTop(obj1);
    var bottom1 = top1 + obj1.offsetHeight;
    var left2 = _this.getElementToPageLeft(obj2);
    var right2 = left2 + obj2.offsetWidth;
    var top2 = _this.getElementToPageTop(obj2);
    var bottom2 = top2 + obj2.offsetHeight;

    if (right1 > left2 && left1 < right2 && bottom1 > top2 && top1 < bottom2) {
      boolCrash = true;
    } else {
      boolCrash = false;
    }
    return boolCrash;
  }
  getElementToPageTop(element) {
    var actualTop = element.offsetTop;
    var current = element.offsetParent;

    while (current !== null) {
      actualTop += current.offsetTop;
      current = current.offsetParent;
    }
    return actualTop;
  }
  getElementToPageLeft(element) {
    var actualLeft = element.offsetLeft;
    var current = element.offsetParent;

    while (current !== null) {
      actualLeft += current.offsetLeft;
      current = current.offsetParent;
    }
    return actualLeft;
  }
  showLoading() {
    if (loadingDom) {
      rootDom.style.overflow = "auto";
      document.body.removeChild(loadingDom);
      loadingDom = null;
    }
    loadingDom = document.createElement("div");
    let maskDom = document.createElement("div");
    maskDom.style.position = "fixed";
    maskDom.style.top = 0;
    maskDom.style.right = 0;
    maskDom.style.bottom = 0;
    maskDom.style.zIndex = 1000;
    maskDom.style.height = "100%";
    maskDom.style.width = "100%";
    maskDom.style.backgroundColor = "rgba(0, 0, 0, 0.65)";
    maskDom.style.display = "flex";
    maskDom.style.justifyContent = "center";
    maskDom.style.alignItems = "center";

    let div = document.createElement("img");
    div.src = loading;
    div.style.height = "80px";
    div.style.width = "80px";

    maskDom.appendChild(div);
    loadingDom.appendChild(maskDom);

    rootDom.style.overflow = "hidden";
    document.body.appendChild(loadingDom);
  }
  closeLoading() {
    if (loadingDom) {
      rootDom.style.overflow = "auto";
      document.body.removeChild(loadingDom);
      loadingDom = null;
    }
  }
  beforeUpload(file) {
    const filename = file.name;
    let isDoc = false;
    // 将文件名反转
    let filenameReverse = filename.split("").reverse().join("");
    // 获取第一个匹配.XX的字符串
    let type = filenameReverse
      .substring(0, filenameReverse.search(/\./))
      .split("")
      .reverse()
      .join("");

    if (fileTypeList.includes(type)) {
      isDoc = true;
    }
    if (!isDoc) {
      message.error("只能上传.doc、.docx、.pdf、.xls、.xlsx文件！");
    }
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error("上传文件不得大于20M!");
    }
    return isDoc && isLt2M;
  }
  fileToBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      console.log(this.result);
    };
  }
  // 计算传给接口的x，y
  returnApiData(img, post, divId) {
    let dom = document.getElementById(divId) || {};
    let divWidth = dom.offsetWidth || 1;
    let divHeight = dom.offsetHeight || 1;

    let imgWidth = img.width || divWidth;
    let imgHeight = img.height || divHeight;

    divHeight = ((divWidth * imgHeight) / imgWidth).toFixed(2);

    let X = post.x;
    let Y = post.y;

    let newX = ((imgWidth * X) / divWidth).toFixed(2);
    let newY = ((imgHeight * Y) / divHeight).toFixed(2);
    // console.log('returnApiData=====')
    // console.log('x', post.x, 'divWidth', divWidth, 'imgWidth', imgWidth, 'newX', newX)
    // console.log('y', post.y, 'divHeight', divHeight, 'imgHeight', imgHeight, 'newY', newY)

    return {
      x: +newX,
      y: +newY,
    };
  }
  // 计算展示的x，y
  returnShowData(img, post, divId) {
    let dom = document.getElementById(divId) || {};
    let divWidth = 800 || 1;
    let divHeight = dom.offsetHeight || 1;

    let imgWidth = img.width || divWidth;
    let imgHeight = img.height || divHeight;
    divHeight = ((divWidth * imgHeight) / imgWidth).toFixed(2);

    let X = post.x;
    let Y = post.y;

    let newX = ((divWidth * X) / imgWidth).toFixed(2);
    let newY = ((divHeight * Y) / imgHeight).toFixed(2);
    // console.log('returnShowData=====')
    // console.log('x', post.x, 'divWidth', divWidth, 'imgWidth', imgWidth, 'newX', newX)
    // console.log('y', post.y, 'divHeight', divHeight, 'imgHeight', imgHeight, 'newY', newY)

    return {
      x: +newX,
      y: +newY,
    };
  }
  // 计算传递给接口的签章、日期的宽度
  returnImgWidth(attachmentWidth, divId, imgWidth) {
    let newImgWidth = imgWidth;
    let dom = document.getElementById(divId) || {};
    let divWidth = 800 || 1;

    attachmentWidth = attachmentWidth ? attachmentWidth : divWidth;

    newImgWidth = (attachmentWidth * imgWidth) / divWidth;
    return newImgWidth;
  }
  // 计算接口返回的签章的宽度，计算实际的宽度
  returnshowImg(attachmentWidth, divId, imgWidth) {
    let newImgWidth = imgWidth;
    let dom = document.getElementById(divId) || {};
    let divWidth = 800 || 1;

    attachmentWidth = attachmentWidth ? attachmentWidth : divWidth;

    newImgWidth = (divWidth * imgWidth) / attachmentWidth;

    return newImgWidth;
  }
  getTimeFn() {
    let time = new Date();
    let y = time.getFullYear();
    let month = time.getMonth() + 1;
    let d = time.getDate();
    let h = time.getHours();
    let m = time.getMinutes();
    let s = time.getSeconds();
    let timeInfo = `${y}${month < 10 ? "0" + month : month}${
      d < 10 ? "0" + d : d
    }${h < 10 ? "0" + h : h}${m < 10 ? "0" + m : m}${s < 10 ? "0" + s : s}`;
    return timeInfo;
  }
}
_this = new utils();
export default _this;
