import { observable, action, flow } from "mobx";
import { getAccountDetail } from "@api/login";

class Store {
  // 登陆状态
  @observable loginStatus = false;
  // 设置登陆状态
  @action seLoinStatus(status) {
    this.loginStatus = status;
  }
  // 三方登录状态
  @observable thirdType = 0;
  // 设置三方登陆状态
  @action setThirdType(type) {
    this.thirdType = type;
  }
  // 三方登录token
  @observable token = "";
  // 设置三方登陆token
  @action setToken(token) {
    this.token = token;
  }
  //用户信息
  @observable user = {};
  // 设置用户信息
  @action setUser(obj) {
    this.user = obj;
  }
  // 获取用户信息
  @action getUserInfo = flow(function* (query, callback) {
    try {
      const res = yield getAccountDetail(query);
      this.setUser(res?.data);

      return typeof callback === "function" && callback(res?.data);
    } catch (error) {
      this.setUser({});
      return typeof callback === "function" && callback(error);
    }
  });
}

export default new Store();

