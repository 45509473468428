const PHONE = {
  'zh-CN': /^(\+?0?86-?)?1[\d]\d{9}$/,
  'ar-DZ': /^(\+?213|0)(5|6|7)\d{8}$/,
  'ar-SY': /^(!?(\+?963)|0)?9\d{8}$/,
  'ar-SA': /^(!?(\+?966)|0)?5\d{8}$/,
  'en-US': /^(\+?1)?[2-9]\d{2}[2-9](?!11)\d{6}$/,
  'cs-CZ': /^(\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
  'de-DE': /^(\+?49[ .-])?([(]{1}[0-9]{1,6}[)])?([0-9 .-/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$/,
  'da-DK': /^(\+?45)?(\d{8})$/,
  'el-GR': /^(\+?30)?(69\d{8})$/,
  'en-AU': /^(\+?61|0)4\d{8}$/,
  'en-GB': /^(\+?44|0)7\d{9}$/,
  'en-HK': /^(\+?852-?)?[569]\d{3}-?\d{4}$/,
  'en-IN': /^(\+?91|0)?[789]\d{9}$/,
  'en-NZ': /^(\+?64|0)2\d{7,9}$/,
  'en-ZA': /^(\+?27|0)\d{9}$/,
  'en-ZM': /^(\+?26)?09[567]\d{7}$/,
  'es-ES': /^(\+?34)?(6\d{1}|7[1234])\d{7}$/,
  'fi-FI': /^(\+?358|0)\s?(4(0|1|2|4|5)?|50)\s?(\d\s?){4,8}\d$/,
  'fr-FR': /^(\+?33|0)[67]\d{8}$/,
  'he-IL': /^(\+972|0)([23489]|5[0248]|77)[1-9]\d{6}/,
  'hu-HU': /^(\+?36)(20|30|70)\d{7}$/,
  'it-IT': /^(\+?39)?\s?3\d{2} ?\d{6,7}$/,
  'ja-JP': /^(\+?81|0)\d{1,4}[ -]?\d{1,4}[ -]?\d{4}$/,
  'ms-MY': /^(\+?6?01){1}(([145]{1}(-|\s)?\d{7,8})|([236789]{1}(\s|-)?\d{7}))$/,
  'nb-NO': /^(\+?47)?[49]\d{7}$/,
  'nl-BE': /^(\+?32|0)4?\d{8}$/,
  'nn-NO': /^(\+?47)?[49]\d{7}$/,
  'pl-PL': /^(\+?48)? ?[5-8]\d ?\d{3} ?\d{2} ?\d{2}$/,
  'pt-BR': /^(\+?55|0)-?[1-9]{2}-?[2-9]{1}\d{3,4}-?\d{4}$/,
  'pt-PT': /^(\+?351)?9[1236]\d{7}$/,
  'ru-RU': /^(\+?7|8)?9\d{9}$/,
  'sr-RS': /^(\+3816|06)[- \d]{5,9}$/,
  'tr-TR': /^(\+?90|0)?5\d{9}$/,
  'vi-VN': /^(\+?84|0)?((1(2([0-9])|6([2-9])|88|99))|(9((?!5)[0-9])))([0-9]{7})$/,
  'zh-TW': /^(\+?886-?|0)?9\d{8}$/,
};

// getPhonePattern函数传参数组和单个传都ok，不传默认中国
// getPhonePattern  是一个方法，接受语言文字标签，输出对应地区的手机号验证正则，
// 当想得到验证多个国家地区的手机号正则时，可以传入多个语言文字标签，
// 或者传入一个包含多个语言文字标签的数组，参数默认值为‘zh - CN’，
// 不传参数直接调用该方法直接返回中国大陆的手机号验证规则
const getPhonePattern = (location = 'zh-CN', ...otherLocation) => {
  location = otherLocation.concat(location);

  if (location.length === 1) {
    return PHONE[location[0]] || new RegExp();
  }

  return new RegExp(
    location
      .filter((item, index) => {
        return !!PHONE[item];
      })
      .map((item, index) => {
        return PHONE[item]
          .toString()
          .slice(1, -1)
          .replace(/\\/g, '\\');
      })
      .join('|')
  );
};

const EMAIL = /^([a-z0-9+_-]+)(\.[a-z0-9+_-]+)*@([a-z0-9-]+\.)+[a-z]{2,6}$/i;
const IDCARD = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/;
const PASSWORD = /^(?=.*\d)(?=.*[A-Za-z])[\x20-\x7e]{8,16}$/



const IP = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export { PHONE, getPhonePattern, EMAIL, IP, PASSWORD,IDCARD };
