import { observable, action, flow, toJS } from 'mobx';
import { message } from 'antd';
import { documentUpload } from '@api/create_sign';
import { addTemplate, setPos, templateView, signIn, preCheck } from '@api/open_api';
import userInfo from '@store/userInfo.store';
import util from '@common/utils';

class Store {

    // 新增的模板ID
    @observable templateId = null;
    @action setTemplateId(templateId) {
        this.templateId = templateId
    }

    // 三方模板id（加密）
    @observable encodedTemplateId = null;
    // 设置三方模板id
    @action setEncodedTemplateId(id) {
        this.encodedTemplateId = id;
    }

    // 上传文件,文件类型：type = 1 ，自定义文件 | type =2 ，模版
    @observable useType = 1;
    @action setUseType(type) {
        this.useType = type
    }
    // 上传合同附件,使用自定义上传
    @observable fileAttachments = [];
    @action addfile(file) {
        this.fileAttachments.push(file)
    }
    @action removeFile(index) {
        let list = toJS(this.fileAttachments);
        list.splice(index, 1)
        this.fileAttachments = list;
    }
    @action setFile(list) {
        this.fileAttachments = list;
    }
    
    // 调用创建合同-step1上传合同附件
    @action documentUpload = flow(function* (query, callback) {
        try {
            const res = yield documentUpload(query, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;' }
            });
            return typeof callback === 'function' && callback(res);
        } catch (error) {
            return typeof callback === 'function' && callback(error);
        }
    })
    // 合同分类
    @observable category = '';
    @action setCategory(category) {
        this.category = category
    }
    // 合同名称
    @observable contractName = "";
    @action setContractName(contractName) {
        this.contractName = contractName
    }

    // 当前操作的合同附件的某一页
    @observable page = 1;
    @action setPage(data) {
        this.page = data;
    }
    // 当前操作的合同附件的唯一流程号
    @observable attachmentObj = {};
    @action setAttachmentObj(data) {
        this.attachmentObj = data;
    }

    // 签章位置数组 
    @observable perSignFileSettingParams = [];
    @action updateParams(data, index) {
        console.log('updateParams===',data)
        let list = toJS(this.perSignFileSettingParams)
        list.splice(index, 1, data)
        this.perSignFileSettingParams = list
    }
    @action addParams(data) {
        console.log('addParams===',data)
        this.perSignFileSettingParams.push(data)
    }
    @action removeParams(index) {
        let list = toJS(this.perSignFileSettingParams)
        list.splice(index, 1)
        this.perSignFileSettingParams = list
    }
    @action setParams(list) {
        this.perSignFileSettingParams = list
    }
    // 附件列表
    @observable attachments = [];
    @action setAttachments(data) {
        this.attachments = data
    }

    // 当前操作的签署人用户对象
    @observable signerObj = {};
    @action setSignerObj(data) {
        this.signerObj = data;
    }
    // 发起人的选择的签章对象
    @observable sigId = {};
    @action setsigId(data) {
        this.sigId = data;
    }
    @action getsigId(){
        return this.sigId
    }

    // 点击下一步，创建模板
    @action checkCreateTemplate = flow(function* (callback) {
        try {
            let attachmentIds = toJS(this.fileAttachments).map(item => item.attachmentId)
            if (attachmentIds.length === 0) {
                message.warning('请上传文件！');
                return typeof callback === 'function' && callback({
                    status: false,
                    noToast: true
                });
            }
            let contractName = toJS(this.contractName).trim();
            if (!contractName) {
                message.warning('请填写合同名称！');
                return typeof callback === 'function' && callback({
                    status: false,
                    noToast: true
                });
            }
            let category = toJS(this.category).trim();
            if (!category) {
                message.warning('请填写模板分类！');
                return typeof callback === 'function' && callback({
                    status: false,
                    noToast: true
                });
            }
            const res = yield addTemplate({
                attachmentId: attachmentIds[0],
                contractName,
                category
            });
            this.setTemplateId(res.data.templateId)

            let attachments = [];
            if(res.data){
                attachments.push(res.data)
            }
            this.setAttachments(attachments);
            
            return typeof callback === 'function' && callback(res);
        } catch (error) {
            return typeof callback === 'function' && callback(error);
        }
    })

    // 点击保存按钮，数据校验
    @action checkSignData() {
        let data = toJS(this.perSignFileSettingParams);
        let hasMasterSign = false;
        let hasMasterDate = false;
        let hasGuestSign = false;
        let hasGuestDate = false;
        data.forEach(item => {
            if (item.isUser && item.sigType === 1) {
                hasMasterSign = true;
            }
            if (item.isUser && item.sigType === 2) {
                hasMasterDate = true;
            }
            if (!item.isUser && item.sigType === 1) {
                hasGuestSign = true;
            }
            if (!item.isUser && item.sigType === 2) {
                hasGuestDate = true;
            }
        })

        return {
            hasMasterSign,
            hasMasterDate,
            hasGuestSign,
            hasGuestDate,
        }
    }

    // 10. 设置签章位置
    @action sigSet = flow(function* (callback) {
        let attachments = toJS(this.attachments) || [];
        let postList = toJS(this.perSignFileSettingParams) || [];
        
        //  根据图片跟显示容器的缩放比，计算实际的x，y
        attachments.forEach(attachment => {
            postList.forEach(post => {
                //if (attachment.attachmentId === post.attachmentId) {
                    let img = {
                        width: attachment.attachmentWidth,
                        height: attachment.attachmentHeight
                    }
                    let returnData = util.returnApiData(img, post, 'bid-show');
                    let imgwidthshow = post.x2 - post.x;
                    let imgWidth = util.returnImgWidth(attachment.attachmentWidth, 'bid-show', imgwidthshow);

                    post.x = returnData.x
                    post.y = returnData.y
                    post.x2 = +((post.x + imgWidth).toFixed(2));

               // }
                if(post.isUser && post.sigType === 1){
                    post.sigId = +toJS(this.sigId).signatureId
                }
            })
        })
        
        let query = {
            posParams: postList,
            templateId: this.templateId
        }
        try {
            const res = yield setPos(query);
            return typeof callback === 'function' && callback(res);
        } catch (error) {
            return typeof callback === 'function' && callback(error);
        }
    })

    // 附件列表
    @observable attachmentsTemplate = [];
    @action setAttachmentsTemplate(data) {
        this.attachmentsTemplate = data
    }

    // 合同名称
    @observable contractNameTemplate = "";
    @action setContractNameTemplate(contractNameTemplate) {
        this.contractNameTemplate = contractNameTemplate
    }

    // 03. 模板详情信息
    @action templateView = flow(function* (id, callback) {
        let query = {
            id
        }
        try {
            const res = yield templateView(query);
            this.setAttachmentsTemplate(res.data.attachments || []);
            this.setContractNameTemplate(res.data.attachments[0].attachmentName || null);
            
            return typeof callback === 'function' && callback(res);
        } catch (error) {
            return typeof callback === 'function' && callback(error);
        }
    })

    // 当前操作的合同附件的唯一流程号
    @observable attachmentTemplateObj = {};
    @action setAttachmentTemplateObj(data) {
        if (data) {
            // 我的签章
            let myPositionResults = data.myPositionResults || [];
            // 已经签署的签章
            let sigPositionResults = data.sigPositionResults || [];
            // 图片宽度
            let attachmentWidth = data.attachmentWidth;
            let attachmentHeight = data.attachmentHeight

            myPositionResults.forEach(post => {
                let img = {
                    width: attachmentWidth,
                    height: attachmentHeight
                }
                console.log('设置前=====')
                console.log('post.attachmentWidth==', attachmentWidth, 'post.x', post.x, 'post.x2', post.x2, 'width', post.x2 - post.x)
                let x = post.x;
                let x2 = post.x2;
                let imgwidth = x2 - x;
                let returnData = util.returnShowData(img, post, 'bid-show');
                let returnShowImg = util.returnshowImg(attachmentWidth, 'bid-show', imgwidth);
                post.x = returnData.x;
                post.x2 = +((post.x + returnShowImg).toFixed(2));
                post.y = returnData.y;
                console.log('设置后=====')
                let width = document.getElementById('bid-show') ? document.getElementById('bid-show').offsetWidth : 1;
                console.log('div width==', width, 'post.x', post.x, 'post.x2', post.x2, 'width', post.x2 - post.x)
            })
            sigPositionResults.forEach(post => {
                let img = {
                    width: attachmentWidth,
                    height: attachmentHeight
                }
                let x = post.x;
                let x2 = post.x2;
                let imgwidth = x2 - x;
                let returnData = util.returnShowData(img, post, 'bid-show');
                let returnShowImg = util.returnshowImg(attachmentWidth, 'bid-show', imgwidth);
                post.x = returnData.x;

                post.x2 = +((post.x + returnShowImg).toFixed(2));
                post.y = returnData.y;
            })
        }

        this.attachmentTemplateObj = data;

    }

    //保存signID
    @observable signId = '';
    @action setSignId(data) {
        this.signId = data;
    }

    // 展示验证码信息
    @observable showpreCheckData = {};
    @action setShowpreCheckData(data) {
        this.showpreCheckData = data;
    }

    // 05. 签署合同
    @action signIn = flow(function* (data, callback) {
        let signId = toJS(this.signId);
        let sigId = +toJS(this.sigId).signatureId;
        let query = {
            signId,
            sigId,
            templateId: toJS(this.templateId),
            //签署状态 sigStatus； 1: 签署 ，-1 ：拒绝
            sigStatus: +data.sigStatus || null,
            // 前置发送的验证码
            signVerifyCode: +data.signVerifyCode || null,
            // 拒签理由
            remark: data.remark || null
        }
        if (!sigId && sigId !== 0) {
            message.warning('请选择签章！');
        }
        try {
            const res = yield signIn(query);
            return typeof callback === 'function' && callback(res);
        } catch (error) {
            return typeof callback === 'function' && callback(error);
        }
    })

    // 04. 签署前置展现信息并发送验证码
    @action preCheck = flow(function* (status, callback) {
        let query = {
            signId: toJS(this.signId),
            // 是否发送验证码； 0: 只显示不发送， 1 ： 显示并发送验证码
            status: status
        }
        try {
            const res = yield preCheck(query);
            this.setShowpreCheckData(res.data);
            return typeof callback === 'function' && callback(res);
        } catch (error) {
            return typeof callback === 'function' && callback(error);
        }
    })
}

export default new Store();