import axios from '../common/http';

// 06. 创建合同-step1上传合同附件
export const documentUpload = (query) => {
    return axios.post('/web/document/upload', query);
};
// 07. 创建合同-step2上传参与人的个人附件
export const personalUpload = (query) => {
    return axios.post('/web/document/personal/attachment/upload', query);
};
// 08.合同创建-step3创建及更新合同信息
export const signCreate = (query) => {
    return axios.post('/web/sign/create', query);
};
// 09. 进去合同设置签章页面
export const signView = (query) => {
    return axios.get('/web/sign/sig/view', query);
};
// 10. 保存草稿/设置签章位置
export const sigSet = (query) => {
    return axios.post('/web/sign/sig/set', query);
};
// 04. 签署前置展现信息并发送验证码
export const preCheck = (query) => {
    return axios.get('/web/sign/preCheck', query);
};
// 批量
export const batchPreCheck = (query) => {
    return axios.get(`/web/sign/batchPreCheck?signIds=${query.signIds}&status=${query.status}`);
};
// 05. 签署/拒签 合同
export const signIn = (query) => {
    return axios.post('/web/sign/in', query);
};
// 批量发起后，发起人签署
export const batchSignIn = (query) => {
    return axios.post('/web/sign/batchSignIn', query);
};
// 批量签署文件
export const batchIn = (query) => {
    return axios.post('/web/sign/batchIn', query);
};
// 03. 签署详情信息
export const signProcess = (query) => {
    return axios.get('/web/sign/process', query);
};
// 12. 进入编辑签约
export const draftView = (query) => {
    return axios.get('/web/sign/draft/view', query);
};

// 上传批量签署人表格
export const batchUpload = (query) => {
    return axios.post('/web/sign/partner/import/' + query.type, query.file);
};