import React, { Suspense, Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch } from "react-router-dom";
import cookie from "js-cookie";
import { Spin } from "antd";
import URoute from "@components/URoute";
import LoadPage from "@components/Loadable";
import { observer } from "mobx-react";
import userInfo from "@store/userInfo.store";
import customStore from "@store/custom.store";
import { getAccountDetail, decodeId, getSignIdByNo } from "@api/open_api";
import utils from "@common/utils.js";
import "@font/iconfont.css";
import "./animate.scss";
import "./index.scss";
import "./customAnimate.scss";

import * as serviceWorker from "./serviceWorker";

const UserUnlogin = LoadPage({
  loader: () => import("./pages/user_unlogin")
});
const App = LoadPage({
  loader: () => import("./App")
});

@observer
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
  async thirdLogin() {
    try {
      let obj = utils.querySearch(window.location);
      if (obj.templateId && obj.sessionId) {
        //三方的用户签署
        let res1 = await decodeId({ code: obj.templateId });
        customStore.setTemplateId(res1.data.templateId);
        customStore.setEncodedTemplateId(obj.templateId);
        console.log("====================================");
        console.log(this.props.history);
        console.log("====================================");
        if (obj.sessionId) {
          const res = await getAccountDetail({ id: obj.sessionId });
          const { status } = res;
          if (status) {
            userInfo.seLoinStatus(true);
            cookie.set("loginstatus", true);
            userInfo.setThirdType(res.data.isThird);
            cookie.set("thirdtype", res.data.isThird);
          } else {
            this.props.history.push("/login");
          }
        }
      } else if (obj.signId && obj.sessionId) {
        //三方的用户的签署详情
        const result = await getSignIdByNo({ contractNo: obj.signId });
        customStore.setSignId(result.data.signId);
        if (obj.sessionId) {
          // const res = await getAccountDetail({ id: obj.sessionId });
          userInfo.seLoinStatus(true);
          cookie.set("loginstatus", true);
          userInfo.setThirdType(3);
          cookie.set("thirdtype", 3);
        }
      } else if (obj.sessionId) {
        //三方用户管理页面
        const res = await getAccountDetail({ id: obj.sessionId });
        const { status } = res;
        if (status) {
          userInfo.seLoinStatus(true);
          cookie.set("loginstatus", true);
          if (res.data.isThird === 1) {
            userInfo.setThirdType(res.data.isThird);
            cookie.set("thirdtype", res.data.isThird);
          } else {
            //非爱豆官方且未提供templateOrSignId
            userInfo.setThirdType(4);
            cookie.set("thirdtype", 4);
          }
        }
      }
      // 获取登陆状态
      let status = cookie.get("loginstatus");
      if (status === "true") {
        userInfo.seLoinStatus(true);
      } else {
        userInfo.seLoinStatus(false);
      }
    } catch (error) {}
  }
  componentDidMount() {
    this.thirdLogin();
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    let loginstatus = userInfo.loginStatus;
    let sessionId = utils.querySearch(window.location).sessionId;
    return !sessionId ? (
      <BrowserRouter>
        <Suspense
          fallback={
            <div className="app-loading">
              <Spin size="large" />
            </div>
          }
        >
          <Switch>
            <URoute
              path="/"
              title={loginstatus ? "登陆" : "首页"}
              render={(props) => {
                if (loginstatus) {
                  // 用户已经登录
                  return <App {...props} />;
                }
                // 用户未登录
                return <UserUnlogin {...props} />;
              }}
            />
          </Switch>
        </Suspense>
      </BrowserRouter>
    ) : (
      <BrowserRouter>
        <Suspense
          fallback={
            <div className="app-idou-loading">
              <Spin size="large" />
            </div>
          }
        >
          <Switch>
            <URoute
              path="/"
              title={loginstatus ? "登陆" : "首页"}
              render={(props) => {
                if (loginstatus) {
                  // 用户已经登录
                  return <App {...props} />;
                }
                // 用户未登录
                //return <UserUnlogin {...props} />
              }}
            />
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Index />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

